"use client";
import HomeComponent from "@/app/home/page";

export default function Home() {
  return (
    <main className="w-full fadein-32ms-linear">
      {/* <p>{username}</p> */}
      <HomeComponent></HomeComponent>
    </main>
  );
}
