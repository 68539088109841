"use client";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import Header from "./header/index";
import Link from "next/link";

import "swiper/css";
import "swiper/css/autoplay";
import "./page.css";
import { useInterval, useMount } from "react-use";
import { EmailService, ThirdGameService, HallService } from "@/service";
import { useAppSelector } from "@/redux/store";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import dayjs, { timeRemaining, TimeRemaining } from "@/utils/dayjs";

import { bannerJump, decodeUrl } from "@/components/Banner/utils";
import BannerLink from "@/components/Banner/BannerLink";
import Img from "@/components/Img";
import { LazyImage } from "@/components/LazyloadImage";
import { Button } from "@/components/Button";
import { styleImage, systemConfigUtils, cdnPrefix } from "@/utils";
import { useDispatch } from "react-redux";
import { ActivityActions } from "@/redux/features/activity-slice";
import { HallActions } from "@/redux/features/hall-slice";
import { EmailActions } from "@/redux/features/email-slice";
import { updataMail } from "@/app/notify/helper";
import { EventGlobalManager } from "@/utils/events";
import { RECEIVE_GIFT_RESULT } from "@/components/RegressActivity";
import { pngList } from "./png";
import { AppDownloadButton } from "@/components/AppDownload";

export function Home() {
  const [swiperList, setSwiperList] = useState<any>([]);
  const [swiperDataIndex, setSwiperDataIndex] = React.useState(0);
  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);
  const multiLanguageGameGroupV1 = useAppSelector((state) => state?.teamSlice?.multiLanguageGameGroupV1);
  const unReadInfo = useAppSelector((state) => state.emailSlice.unReadInfo);
  const mailList = useAppSelector((state) => state.emailSlice.mailList);
  const mailData = useAppSelector((state) => state.emailSlice.mailData);
  const bannerHall = useAppSelector((state) => state.hallSlice.bannerHall);
  const isFCP = useAppSelector((state) => state.hallSlice.isFCP);

  const dispatch = useDispatch();

  const [reciprocalList, setReciprocalList] = useState<any>({});
  const [preloadingList, setPreloadingList] = useState<string[]>([]);
  const swiperRef = useRef<any>();

  useEffect(() => {
    dispatch(HallActions.setFCP(true));
    HallService.bannerHallReq(usr_basic_info?.uid!, 1);
    ThirdGameService.gameGroupV1(usr_basic_info?.uid!);
    EmailService.reqUnReadInfoReq(usr_basic_info?.uid!);
  }, [usr_basic_info?.uid]);

  useMount(() => {
    // 回归福利、回归礼包领取成功刷新banner
    EventGlobalManager.on(RECEIVE_GIFT_RESULT, (result: boolean) => {
      if (result) {
        HallService.bannerHallReq(usr_basic_info?.uid!, 1);
      }
    });
    preloading();
    dispatch(HallActions.setFace(true));
  });

  useEffect(() => {
    // 判断dom节点可以获取判断首屏加载完成
    if (swiperRef.current) {
      dispatch(HallActions.setFCP(true));
    }
  }, [swiperRef]);

  // 获得轮播图数据
  useEffect(() => {
    if (bannerHall?.data_list?.length! > 0) {
      const data = bannerHall?.data_list?.map((val) => {
        return {
          banner_id: val?.banner_id,
          is_show_time: val?.is_show_time,
          img: val?.pic,
          title: val?.jump_button,
          alt: val?.title,
          link: bannerJump(val),
          jump: val.jump,
        };
      });
      setSwiperList(data);
    }
  }, [bannerHall]);

  // 请求邮件最大数，判断是否要请求邮件数据
  useEffect(() => {
    if (isFCP) {
      updataMail(unReadInfo?.max_mail_id!, unReadInfo?.min_mail_id!, usr_basic_info?.uid!);
    }
  }, [unReadInfo?.min_mail_id, unReadInfo?.max_mail_id, isFCP]);

  // 拼接邮件数据列表
  useEffect(() => {
    if (mailList?.mail_list?.length) {
      const data = [...(mailData || []), ...mailList?.mail_list!];
      let mailIdList: number[] = [];
      const mail = data?.filter((val) => {
        if (mailIdList?.includes(val?.mail_id!)) {
          return false;
        } else {
          mailIdList.push(val?.mail_id!);
          return true;
        }
      });
      dispatch(EmailActions.setMailData(mail));
      dispatch(EmailActions.setMailList({}));
      StorageData.local.set(StoreKey.EMAIL_LIST, JSON.stringify(mail));
    }
  }, [mailList]);

  const goBannerDetail = (e: any, banner: any) => {
    if (banner.link === "activitygift") {
      e.preventDefault();
      const jumpData = decodeUrl(banner.jump);
      dispatch(
        ActivityActions.setShowRegressActivty({
          id: jumpData?.gt_params!,
        }),
      );
    }
  };

  // 获取轮播图倒计时
  const countTime = (curInfo: any) => {
    const isShowTime = curInfo?.is_show_time != 0;
    let allRunTime = 0;
    if (!systemConfigUtils?.delayTime) {
      return allRunTime;
    }
    if (isShowTime) {
      if (curInfo?.count_down_show > 0) {
        allRunTime = Math.floor((systemConfigUtils?.delayTime + curInfo?.count_down_show * 1000) / 1000);
      } else {
        if (curInfo.show_type != 2) {
          //永久显示 24小时制度
          let today = systemConfigUtils?.delayTime;
          let date = new Date(today);
          date.setHours(24, 0, 0, 0);
          //结束时间戳 毫秒
          let timestamp = Math.floor(date.getTime() / 1000);

          allRunTime = timestamp;
        } else {
          //2为自定义时间
          let startTime = systemConfigUtils?.delayTime;
          let endTime = curInfo?.show_end_time;
          allRunTime = endTime;
          if (allRunTime < 0) {
            allRunTime = 0;
          }
        }
      }
      return allRunTime;
    } else {
      return 0;
    }
  };

  useInterval(() => {
    let obj = {};
    let timeout = false;
    bannerHall?.data_list?.forEach((val) => {
      const endTime = countTime(val);
      const time = endTime * 1000 - systemConfigUtils?.delayTime;
      // 当有活动时间结束请求新的活动列表,且结束时间不为0
      if (parseInt(String(time / 1000)) <= 0 && endTime !== 0) {
        timeout = true;
      }
      obj = {
        ...obj,
        [val?.banner_id!]: timeData(time),
      };
    });
    if (timeout) {
      HallService.bannerHallReq(usr_basic_info?.uid!, 1);
    }
    setReciprocalList(obj);
  }, 1000);

  // 将时间戳转换成00:00:00
  const timeData = useCallback((value: number) => {
    const data = timeRemaining(value) as TimeRemaining;
    const day = Number(data?.day) * 24;
    const hours = Number(data?.hours) + day > 0 ? Number(data?.hours) + day : "00";
    const minutes = Number(data?.minutes) > 0 ? data?.minutes : "00";
    const seconds = Number(data?.seconds) > 0 ? data?.seconds : "00";
    return `${Number(hours) < 10 && Number(hours) > 0 ? "0" : ""}${hours} : ${minutes} : ${seconds}`;
  }, []);

  // 预加载转盘图片
  const preloading = () => {
    const imgList = ["turntable/dialog/xiangzi01.png", "turntable/dadi.png"];
    setPreloadingList(imgList);
  };

  return (
    <div className="homeContainer fadein-32ms-linear">
      <Header pageType={2}></Header>
      <div className="homeContent">
        <div className="slideshow">
          <Swiper
            ref={swiperRef}
            spaceBetween={0}
            loop
            autoplay={{
              delay: 3000,
            }}
            slidesPerView={1}
            onSlideChange={(swiper) => setSwiperDataIndex(swiper?.realIndex)}
            pagination={true}
            modules={[Pagination, Autoplay]}
            className="!bg-[length:100%_100%] h-[7.6rem]"
            style={styleImage("home/cc_yj_bj.png")}
          >
            {swiperList?.map((val: any, index: number) => {
              return (
                <SwiperSlide key={index} className="pt-[0.2rem]">
                  <BannerLink prefetch={true} href={val?.link || "/"} onClick={(e: any) => goBannerDetail(e, val)}>
                    <div className="swiperSlideBox m-auto relative h-[5.9rem]">
                      <div className="h-[5.6rem]">
                        <LazyImage
                          height={"auto"}
                          src={val?.img}
                          alt={val?.alt}
                          divProps={{ className: "m-auto h-full" }}
                        />
                      </div>
                      {val?.is_show_time !== 0 && countTime(val) !== 0 && (
                        <div
                          className="text-[#fff] text-center mt-[-0.5rem] w-[2rem] bg-no-repeat mx-auto z-50 absolute bottom-0 right-0 left-0 "
                          style={{ backgroundImage: `url(${pngList.djs_di})`, backgroundSize: "100%" }}
                        >
                          {reciprocalList?.[val?.banner_id]}
                        </div>
                      )}
                    </div>
                  </BannerLink>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {swiperList && swiperList.length > 0 && (
            <div className="swiperBtn text-center">
              <Button className="min-w-[3rem] max-w-[6.68rem] truncate !text-[#E6E6E6]">
                <Link
                  prefetch={true}
                  href={swiperList?.[swiperDataIndex]?.link || "/"}
                  onClick={(e: any) => goBannerDetail(e, swiperList?.[swiperDataIndex])}
                >
                  <span>{swiperList?.[swiperDataIndex]?.title}</span>
                </Link>
              </Button>
            </div>
          )}
        </div>

        <div className="homeList">
          {multiLanguageGameGroupV1?.categoryList?.map((val, index) => {
            return (
              <Link prefetch={true} href={`/gameList?gametype=${val?.id}`} key={val?.id}>
                <div className="homeListContent btn_animation">
                  <Img className="w-full" src={val?.cover} alt={val?.desc} />
                </div>
              </Link>
            );
          })}
        </div>
        <div className="Preloading">
          {preloadingList?.map((val, index) => {
            return <Img key={index} className="w-0 h-[0]" src={cdnPrefix(val)} importance="low" />;
          })}
        </div>
      </div>

      <AppDownloadButton />
    </div>
  );
}

export default Home;
